import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { UserService } from '../services/app/user.service';

export const loggerInterceptor: HttpInterceptorFn = (req, next) => {
  const userService: UserService = inject(UserService);
  const token = userService.get('token');
  const authReq = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${token}`),
  });
  return next(authReq);
};
