import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { UserService } from '../services/app/user.service';

export const authGuard: CanActivateFn = (route, state) => {
  const userService = inject(UserService);
  const routerService = inject(Router);

  const token = userService.get('token');

  if (!token) {
    return routerService.createUrlTree(['/auth/login']);
  }
  return true;
};
