import { HttpInterceptorFn, HttpErrorResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { NoticeService } from '../services/app/notice.service';
import { inject } from '@angular/core';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { UserService } from '../services/app/user.service';

export const errorInterceptor: HttpInterceptorFn = (req, next) => {
  const noticeService = inject(NoticeService);
  const userService = inject(UserService);
  const router = inject(Router);

  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      let errorMessage = 'Error desconocido';
      switch (error.status) {
        case 400:
          errorMessage = 'Solicitud incorrecta';
          break;
        case 401:
          errorMessage = 'No autorizado';
          break;
        case 403:
          userService.clear();
          router.navigate(['/auth/login']);
          errorMessage = 'Acceso prohibido';
          break;
        case 404:
          errorMessage = 'Recurso no encontrado';
          break;
        case 405:
          errorMessage = 'Método no permitido';
          break;
        case 408:
          errorMessage = 'Tiempo de espera de la solicitud agotado';
          break;
        case 409:
          errorMessage = 'Conflicto en la solicitud';
          break;
        case 410:
          errorMessage = 'Recurso ya no está disponible';
          break;
        case 413:
          errorMessage = 'Solicitud demasiado grande';
          break;
        case 415:
          errorMessage = 'Tipo de medio no admitido';
          break;
        case 429:
          errorMessage = 'Demasiadas solicitudes';
          break;
        case 500:
          userService.clear();
          router.navigate(['/auth/login']);
          errorMessage = 'Error interno del servidor';
          break;
        case 501:
          errorMessage = 'No implementado';
          break;
        case 503:
          errorMessage = 'Servicio no disponible';
          break;
        case 504:
          errorMessage = 'Tiempo de espera agotado del servidor';
          break;
        default:
          errorMessage = 'Error: ' + error.message;
          break;
      }
      noticeService.showSnackbar(errorMessage, 'Cerrar');
      return throwError(() => error);
    })
  );
};
