import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, HttpClientModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'Limpiezas';

  public installPrompt: any = null;
  constructor(private swUpdate: SwUpdate) {
    
    // if (this.swUpdate.available) {
    //   this.swUpdate.available.subscribe(() => {
    //     if (confirm('Nueva versión disponible. ¿Desea actualizar?'))
    //       window.location.reload();
    //   });
    // }
  }

  getInstallPrompt(): void {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installPrompt = e;
    });
  }

  askUserToInstallApp() {
    this.installPrompt.promt();
  }
}
